import Aos from 'aos';
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

function HeaderHomeContactos(props) {
    useEffect(() => {
        Aos.init();
      }, [])
  return (
    <div className='' data-aos="fade-up">
        <Col className={props.section === "header" ? 'd-flex flex-column' : 'd-none'}>
            <div className='d-inline-block mx-auto mb-2'>
                <h1 className='text-center display-5 w-100 bg-white px-4 py-2'><span className='fw-bold'>INSPIRACIÓN</span> <b>PARA TRABAJAR</b></h1>
            </div>
            <div style={{height:15, width: 150}} className="bg-secondary bg-opacity-50 rounded-pill mx-auto"></div>
        </Col>
        <Row className={props.section === "scc1" ? 'my-5 d-lg-none' : 'd-none d-lg-flex mt-5'}  >
            <Col lg="4" className='d-flex justify-content-center align-items-center py-3 py-lg-0'>
                <div className='border border-dark border-3 rounded-circle d-flex justify-content-center align-items-center p-1'>
                    <i className='bx bx-phone fs-1 p- m-0' ></i>
                </div>
                <div className='d-inline-flex flex-column ms-3 align-items-center'>
                    <a href="tel:3340089108" target="blank" className='text-decoration-none text-dark fs-5'>(33) 4008 9108</a>
                    <a href="tel:3314358204" target="blank" className='text-decoration-none text-dark fs-5'>(33) 1435 8204</a>
                </div>
            </Col>
            <Col lg="4" className='d-flex justify-content-center align-items-center py-3 py-lg-0 border-end border-start border-dark border-3 my-4 my-lg-0'>
                <div className='border border-dark border-3 rounded-circle d-flex justify-content-center align-items-center p-1'>
                    <i className='bx bx-envelope fs-1 p- m-0' ></i>
                </div>
                <div className='d-inline-flex flex-column ms-3 align-items-center'>
                    <a href="mailto:admin@enlacecoworking.mx" target="blank" className='text-decoration-none text-dark fs-5'>admin@enlacecoworking.mx</a>
                    <a href="mailto:direccion@enlacecoworking.mx" target="blank" className='text-decoration-none text-dark fs-5'>direccion@enlacecoworking.mx</a>
                </div>
            </Col>
            <Col lg="4" className='d-flex justify-content-center align-items-center py-3 py-lg-0'>
                <div className=' border border-dark border-3 rounded-circle d-flex justify-content-center align-items-center p-1'>
                    <i className='bx bx-map fs-1 p- m-0' ></i>
                </div>
                <div className='d-inline-flex flex-column ms-3 align-items-center'>
                    <a href="https://goo.gl/maps/zf435a9TMqPLJ7ePA" target="blank" className='text-decoration-none text-dark fs-5 lh-1'>Herrera y Cairo No. 342, <br />Colonia Centro, C.P. 45500, <br />San Pedro Tlaquepaque</a>
                </div>
            </Col>
        </Row>
    </div>
  )
}

export default HeaderHomeContactos