import React, { useEffect, useRef, useState } from 'react'
import Footer from '../includes/Footer'
import AOS from 'aos';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import HeaderHomeContactos from '../sections/HeaderHomeContactos';
import NavbarTop from '../includes/NavbarTop';

function Home() {
  const sliderRef = useRef();
  const sliderRef2 = useRef();
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings2 = {
    centerMode: true,
    draggable:false,
    slidesToShow: 3,
    dots: true,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const settings1 = {
    dots: false,
    draggable:false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: false,
    centerPadding: "0px",
    beforeChange: function(currentSlide, nextSlide) {
      setCurrentSlide(nextSlide)
    }
  };

  const handleOnClick = index => {
    sliderRef.current.slickGoTo(index);
  };

  useEffect(() => {
    AOS.init();
    setTimeout(() => {
      AOS.refresh();
    }, 1000)
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <NavbarTop modulo="home"/>
      <Container fluid>
        <Row className='position-relative '>
          <Col xs="12" className='p-0 position-relative' data-aos-anchor-placement="top-bottom" data-aos="fade-down" >
            <div >
              <Slider {...settings1} className="" ref={sliderRef}>
                {
                  [1,2,3,4].map((e,i) => {
                    return(
                      <div className={'vh-100 bg-header-' + e} key={i} ></div>
                    )
                  })
                }
              </Slider>
            </div>
            <div className='position-absolute top-50 end-0 translate-middle-y me-3 d-flex flex-column'>
              <button onClick={() => handleOnClick(0)} className='btn p-0 border-0 text-secondary bg-opacity-50'>
                <i className={currentSlide === 0 ? 'p-1 fs-1 bx bx-radio-circle-marked' : 'p-1 fs-1 bx bx-radio-circle' }></i>
              </button>
              <button onClick={() => handleOnClick(1)} className='btn p-0 border-0 text-secondary bg-opacity-50'>
                <i className={currentSlide === 1 ? 'p-1 fs-1 bx bx-radio-circle-marked' : 'p-1 fs-1 bx bx-radio-circle' }></i>
              </button>
              <button onClick={() => handleOnClick(2)} className='btn p-0 border-0 text-secondary bg-opacity-50'>
                <i className={currentSlide === 2 ? 'p-1 fs-1 bx bx-radio-circle-marked' : 'p-1 fs-1 bx bx-radio-circle' }></i>
              </button>
              <button onClick={() => handleOnClick(3)} className='btn p-0 border-0 text-secondary bg-opacity-50'>
                <i className={currentSlide === 3 ? 'p-1 fs-1 bx bx-radio-circle-marked' : 'p-1 fs-1 bx bx-radio-circle' }></i>
              </button>
            </div>
          </Col>
          <Col xs="12" className='position-absolute bottom-0 start-50 translate-middle-x mb-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <HeaderHomeContactos section="header" />
          </Col>
        </Row>
        <HeaderHomeContactos section="scc1" />
      </Container>
      <Container fluid className='mb-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
        <Row className='text-center text-white bg-home-1 py-5'>
          <Col>
            <h1 className='mt-5 display-3 fw-bold theboldfont' data-aos-anchor-placement="top-bottom" data-aos="fade-up">APOYAMOS TUS SUEÑOS</h1>
            <p className='fs-4' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Te ofrecemos espacios para impulsar a tu empresa</p>
            <a href="assets/pdf/Brochure_enlace.pdf" download={true} className='btn btn-light mx-auto d-inline-block mb-5'>Descargar Brochure</a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg="4" className='px-4' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <img src="assets/images/Main/3.jpg" alt="Imagen 1" className='w-100 mb-4' />
            <h1 className='mb-0'>OFICINAS</h1>
            <h1 className='d-flex align-items-end mb-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              <b>PRIVADAS</b>
              <div className='w-100 ps-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
                <div style={{height:10}} className="bg-secondary bg-opacity-50 rounded-pill mx-auto w-100 mb-2"></div>
              </div>
            </h1>
            <p className='text-justify mt-4' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Establezca una base para su empresa con una oficina privada en uno de nuestros distritos financieros. Nuestras oficinas equipadas tienen todo bajo control desde el mobiliario hasta wifi de alta velocidad.</p>
          </Col>
          <Col lg="4" className='px-4 mt-5 mt-lg-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <img src="assets/images/Main/2.jpg" alt="Imagen 1" className='w-100 mb-4' />
            <h1 className='mb-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">SALA</h1>
            <h1 className='d-flex align-items-end mb-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
              DE <b className='ms-3'>JUNTAS</b>
              <div className='w-100 ps-5'>
                <div data-aos-anchor-placement="top-bottom" data-aos="fade-up" style={{height:10}} className="bg-secondary bg-opacity-50 rounded-pill mx-auto w-100 mb-2"></div>
              </div>
            </h1>
            <p className='text-justify mt-4' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Impresione a sus clientes, dirija talleres memorables o un gran discurso en nuestras salas de juntas totalmente equipadas.</p>
          </Col>
          <Col lg="4" className='px-4 mt-5 mt-lg-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <img src="assets/images/Main/1.jpg" alt="Imagen 1" className='w-100 mb-4' />
            <h1 className='mb-0 d-none d-lg-block'><br /></h1>
            <h1 className='d-flex align-items-end mb-0'>
            <b data-aos-anchor-placement="top-bottom" data-aos="fade-up">COWORKING</b>
              <div data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='w-100 ps-5'>
                <div style={{height:10}} className="bg-secondary bg-opacity-50 rounded-pill mx-auto w-100 mb-2"></div>
              </div>
            </h1>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-justify mt-4'>Nuestros espacios de coworking están pensados con un diseño de colaboración para que trabaje junto a una comunidad de ideas afines en nuestra oficina compartida. </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className='my-lg-5'>
        <Row className='py-lg-5'>
          <Col id="slideHome2" className='position-relative px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
            <Slider {...settings2} ref={sliderRef2}>
              {
                [1,2,3,4,5,6,7,8,9,10,11,12].map((e,i) => {
                  return (
                    <div key={i}>
                      <img src={`assets/images/Main/Galeria/${e}.jpg`} alt="Imagen 1" className='mb-4 w-100'/>
                    </div>
                  )
                })
              }
              
            </Slider>
            <button className='position-absolute top-50 end-0 translate-middle-y p-0 border-0 btn' onClick={() => sliderRef2.current.slickNext()}>
              <i class='bx bx-chevron-right display-3 text-secondary bg-opacity-50'></i>
            </button>
            <button className='position-absolute top-50 start-0 translate-middle-y p-0 border-0 btn' onClick={() => sliderRef2.current.slickPrev()}>
              <i class='bx bx-chevron-left display-3 text-secondary bg-opacity-50'></i>
            </button>
          </Col>
        </Row>
      </Container>
      <Container fluid className='my-5'>
        <Row className='text-center text-white bg-home-2 py-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up"> 
          <h1 className='my-5 display-3 fw-bold theboldfont' data-aos-anchor-placement="top-bottom" data-aos="fade-up">TRANSFORMANDO EL FUTURO</h1>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg="4" className="text-center px-5">
           <img src="assets/images/Main/Icono-1.png" alt="icono 1" data-aos-anchor-placement="top-bottom" data-aos="fade-up" />
           <h1 className='d-block my-4 fw-bold' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Capacitación</h1>
            <p className='d-block mb-0' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Queremos enseñarte todo, para que puedas seguir creciendo profesionalmente.</p>
            <p className='d-block' data-aos-anchor-placement="top-bottom" data-aos="fade-up">¡Las personas exitosas nunca dejan de prepararse!</p>
          </Col>
          <Col lg="4" className="text-center px-5">
           <img src="assets/images/Main/Icono-2.png" alt="icono 1" data-aos-anchor-placement="top-bottom" data-aos="fade-up" />
           <h1 className='d-block my-4 fw-bold' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Comunidades</h1>
            <p className='d-block' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Dos cabezas piensan mejor que una, imagina ahora el poder de cientos. En nuestras comunidades podrás encontrar a más personas con los mismos objetivos que tú.</p>
          </Col>
          <Col lg="4" className="text-center px-5">
           <img src="assets/images/Main/Icono-3.png" alt="icono 1" data-aos-anchor-placement="top-bottom" data-aos="fade-up" />
           <h1 className='d-block my-4 fw-bold' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Eventos</h1>
            <p className='d-block' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Te brindamos herramientas para hacer crecer tu negocio y también para que aprenderás a identificar cambios que son necesarios para adaptarte a innovaciones disruptiva.</p>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )
}

export default Home