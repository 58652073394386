import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function NavbarTop(props) {
  return (
    <Navbar bg="light" expand="lg" className='fixed-top navbar bg-opacity-75 py-4' data-aos="fade-down">
        <Container>
            <Link to="/"><img src="assets/images/logo_enlace.png" alt="Logo Enlace" /></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className='border-0' />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto pt-3 pt-lg-0">
                    <Link className={props.modulo === "home" ? 'btn bg-secondary text-white p-0 rounded-pill px-4 fs-5 py-1' : 'btn btn-outline-secondary border-0 p-0 rounded-pill px-4 fs-5 py-1'} to="/">Inicio</Link>
                    <Link className={props.modulo === "servicios" ? 'btn bg-secondary text-white p-0 rounded-pill px-4 fs-5 py-1 ms-lg-3 mt-2 mt-lg-0' : 'btn btn-outline-secondary border-0 p-0 rounded-pill px-4 fs-5 py-1 ms-lg-3 mt-2 mt-lg-0'} to="/Servicios">Servicios</Link>
                    <Link className={props.modulo === "contacto" ? 'btn bg-secondary text-white p-0 rounded-pill px-4 fs-5 py-1 ms-lg-3 mt-2 mt-lg-0' : 'btn btn-outline-secondary border-0 p-0 rounded-pill px-4 fs-5 py-1 ms-lg-3 mt-2 mt-lg-0'} to="/Contacto">Contacto</Link>
                    <div className='d-flex justify-content-center mt-4 mt-lg-0'>
                        <a href="https://wa.me/3314358204" target="blank" className='btn text-muted scale transition fs-5 fw-bold py-0 rounded-pill px-1 border-0 ms-lg-3'><i class='bx bxl-whatsapp fs-3 py-1' ></i></a>
                        <a href="https://www.facebook.com/" target="blank" className='btn text-muted scale transition fs-5 fw-bold py-0 rounded-pill px-1 border-0  ms-2'><i className='bx bxl-facebook fs-3 py-1' ></i></a>
                        <a href="mailto:admin@enlacecoworking.mx" target="blank" className='btn text-muted scale transition fs-5 fw-bold py-0 rounded-pill px-1 border-0  ms-2'><i className='bx bx-envelope fs-3 py-1' ></i></a>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}

export default NavbarTop