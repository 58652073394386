import Aos from 'aos'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../includes/Footer'
import NavbarTop from '../includes/NavbarTop'
function Contacto() {
  useEffect(() => {
    Aos.init();
    setTimeout(() => {
      Aos.refresh();
    }, 500)
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <NavbarTop modulo="contacto"/>
      <Container fluid>
        <Row>
          <Col lg="12" className="min-vh-100 position-relative d-grid">
            <img src="assets/images/Contacto/Banner.jpg" alt="banner servicio" className='position-absolute top-0 start-0 w-100 h-100 fit-image p-0 zIndex-1' data-aos-anchor-placement="top-bottom" data-aos="fade-down" />
            <div className='d-inline-block mt-auto mb-5 d-grid position-relative zIndex-2'>
              <div className='d-inline-block mx-auto mb-3'>
                  <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-center display-5 w-100 fw-bolder bg-white px-4 py-2'><b>LOS SERVICIOS</b><span className='fw-light'> QUE TÚ NECESITAS</span> </h1>
              </div>
              <div data-aos-anchor-placement="top-bottom" data-aos="fade-up" style={{height:15, width: 150}} className="bg-secondary bg-opacity-50 rounded-pill mx-auto"></div>
            </div>
          </Col>
        </Row>
        
      </Container>
      <Container fluid className='bg-home-1 py-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
        <Container>
          <Row >
            <Col lg="4" className='py-3 d-grid'>
              <i data-aos-anchor-placement="top-bottom" data-aos="fade-up" class='bx bx-time-five d-block text-center display-1 m-0 text-white'></i>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-white fs-4  text-center mb-0'>Lunes a viernes de <br /> 9:00 am a 7:00 pm.</p>
            </Col>
            <Col lg="4" className='py-3 d-grid'>
                <i data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bx bx-envelope d-block text-center display-1 m-0 text-white' ></i>
                <div className='d-inline-flex flex-column align-items-center'>
                    <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="mailto:admin@enlacecoworking.mx" target="blank" className='text-decoration-none text-white text-center fs-4'>admin@enlacecoworking.mx</a>
                    <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="mailto:direccion@enlacecoworking.mx" target="blank" className='text-decoration-none text-white text-center fs-4'>direccion@enlacecoworking.mx</a>
                </div>
            </Col>
            <Col lg="4" className='py-3 d-grid'>
                <i data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bx bx-phone d-block text-center display-1 m-0 text-white' ></i>
                <div className='d-inline-flex flex-column align-items-center justify-content-center'>
                    <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="tel:3340089108" target="blank" className='text-decoration-none text-white text-center fs-4'>(33) 4008 9108</a>
                    <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="tel:3314358204" target="blank" className='text-decoration-none text-white text-center fs-4'>(33) 1435 8204</a>
                </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='mt-5'>
        <Row>
          <Col lg="6">
            <iframe data-aos-anchor-placement="top-bottom" data-aos="fade-up" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.924125179662!2d-103.3110178!3d20.631949299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3ad151616c7%3A0x7cc2ad697d909c16!2sC.%20Herrera%20y%20Cairo%20342%2C%20La%20Capacha%2C%2045520%20San%20Pedro%20Tlaquepaque%2C%20Jal.!5e0!3m2!1ses!2smx!4v1670979686797!5m2!1ses!2smx" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>
          <Col lg="6" className='d-grid px-4'>
            <div className='mt-auto d-grid'>
              <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary display-2 fw-bold ms-lg-2 text-center text-lg-start texty-lg-start mt-5 mt-lg-0 mb-4 mb-lg-0'>UBICACIÓN</h1>
              <div className='d-flex align-items-center mb-5'>
                <i data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bx bxs-map display-1 text-secondary me-3' ></i>
                <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="https://goo.gl/maps/zf435a9TMqPLJ7ePA" target="blank" className='text-decoration-none text-secondary fs-5 text-justify lh-1'>Herrera y Cairo No. 342, Colonia <br className='d-none d-md-block' /> Centro, C.P. 45500, San Pedro<br className='d-none d-md-block' /> Tlaquepaque</a>
              </div>
              <div className='d-inline-flex align-items-center justify-content-center my-3 mx-auto ms-lg-0'>
                <i data-aos-anchor-placement="top-bottom" data-aos="fade-up" class='bx bxl-whatsapp fs-2 me-2 text-secondary' ></i>
                <a data-aos-anchor-placement="top-bottom" data-aos="fade-up" href="https://wa.me/3314358204" target="blank" className='text-decoration-none text-secondary fs-5 fw-bold'>(33) 1435 8204</a>
              </div>
              <div className='d-flex justify-content-between d-lg-block'>
                <Link to="/Servicios" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='btn border-0 bg-secondary bg-opacity-75 rounded-pill px-4 fw-bold text-white'>Ver servicios</Link>
                <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='btn border-0 bg-success bg-opacity-75 rounded-pill px-5 fw-bold text-white ms-3'>Cotizar</a>
              </div>
            </div>
          
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )
}

export default Contacto