import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Footer from '../includes/Footer'
import NavbarTop from '../includes/NavbarTop'
import Aos from 'aos';

function Servicios() {

  const [textForm, setTextForm] = useState("Suscribete")
  const [isDisabled, setIsDisabled] = useState(false)

  const handleSubmit = e => {
    e.preventDefault();
    setTextForm("Verificando..")
    setIsDisabled(true)
    axios.post('https://enlacecoworking.com/mail_enlace/index.php', {
            email : e.target[0].value
        })
        .then(function (response) {
          e.target.reset();
          setTextForm("Suscribete")
          setIsDisabled(false)
          toast.success('Correo Enviado!!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        })
        .catch(function (error) {
            console.log(error);
            setTextForm("Suscribete")
            setIsDisabled(false)
            toast.error('Ocurrio un error, comunicate con el administrador!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
        });
  }

  useEffect(() => {
        Aos.init();
        setTimeout(() => {
          Aos.refresh();
        }, 500)
        window.scrollTo(0, 0)
      }, [])

  return (
    <>
      <NavbarTop modulo="servicios"/>
      <Container fluid>
        <Row>
          <Col lg="12" className="vh-100 position-relative">
            <img src="assets/images/Servicios/banner.jpg" data-aos-anchor-placement="top-bottom" data-aos="fade-down" alt="banner servicio" className='position-absolute top-0 start-0 w-100 h-100 fit-image p-0' />
          </Col>
        </Row>
        <Row className='bg-home-2' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
          <Col>
            <h1 className='text-light text-center display-1 fw-bold py-5 my-2 theboldfont' data-aos-anchor-placement="top-bottom" data-aos="fade-up">NUESTRA FILOSOFÍA</h1>
          </Col>
        </Row>
      </Container>
      <Container className='py-5'>
        <Row>
          <Col lg="6" className='pt-5 p-lg-5 border-end border-dark border-2'>
            <h1 className='display-6 fw-bold px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up"><span className='display-4 fw-bold me-2'>1.</span> Misión</h1>
            <p className='text-justify px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Convertirnos en un referente creador de oportunidades para emprendedores, pequeños empresarios y profesionistas que inician su camino empresarial, creando espacios creativos, funcionales y que generen conexiones comerciales y sociales.</p>
          </Col>
          <Col lg="6" className='pt-5 p-lg-5'>
            <h1 className='display-6 fw-bold px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up"><span className='display-4 fw-bold me-2'>2.</span> Visión</h1>
            <p className='text-justify px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">CREAR, CREER, SER, una comunidad de empresarios con una cultura de innovacion y valores que aporten a cada socio, una visión diferente de los negocios.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className='pt-5 p-lg-5 border-end border-dark border-2'>
            <h1 className='display-6 fw-bold px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up"><span className='display-4 fw-bold me-2'>3.</span> Filosofía</h1>
            <p className='text-justify px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Parte de nuestra folosofía empresarial, es que ENLACE PRIME COWORKING es más que espacios de trabajo, incluso más que un emprendimiento social, es el compromiso que se tiene con la sociedad de crear oportunidades y formular nuevas maneras de hacer mejor las cosas.</p>
          </Col>
          <Col lg="6" className='pt-5 p-lg-5'>
            <h1 className='display-6 fw-bold px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up"><span className='display-4 fw-bold me-2'>4.</span> Valores</h1>
            <p className='text-justify px-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Respeto,  Ética, Responsabilidad, Gratitud, Solidaridad, Honestidad, Profesionalismo y Bondad.</p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className='bg-servicios-1' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
          <Col className='text-center text-light'>
            <h1 className='mt-5 display-3 fw-bold theboldfont' data-aos-anchor-placement="top-bottom" data-aos="fade-up">NUESTROS ESPACIOS</h1>
            <p className='mb-5 fs-4' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Nos encargamos de que el lugar que elijas refleje los valores <br className='d-none d-lg-block' /> y objetivos de tu empresa.</p>
          </Col>
        </Row>
      </Container>
      <Container className='py-5 mt-5'>
        <Row>
          <Col lg="4" className='d-flex align-items-center flex-column px-5'>
            <img src="assets/images/Servicios/junior.png" alt="icon" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mx-auto' />
            <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-center fs-2 my-3 fw-bold">Membresía junior</h1>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Aprovecha las oportunidades para aprender, hacer contactos y relajarte en nuestras instalaciones.</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 60 horas al mes</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 4 horas en sala de juntas (No acumulables)</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> No incluye café americano, impresiones y acceso a lockers</p>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-purple rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
          <Col lg="4" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center flex-column px-5 mt-5 mt-lg-0'>
            <img src="assets/images/Servicios/Senior.png" alt="icon" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mx-auto' />
            <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-center fs-2 my-3 fw-bold">Membresía senior</h1>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Te  brindamos todo lo que necesitas para que tu empresa alcance el éxito.</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 80 horas al mes</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 8 horas en sala de juntas (No acumulables)</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> Incluye café americano</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> No incluye impresiones y acceso a lockers</p>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-purple rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
          <Col lg="4" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center flex-column px-5 mt-5 mt-lg-0'>
            <img src="assets/images/Servicios/prime.png" alt="icon" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mx-auto' />
            <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-center fs-2 my-3 fw-bold">Membresía prime</h1>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Espacios de coworking diseñados para la colaboración.</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 100 horas al mes</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> 12 horas en sala de juntas (No acumulables)</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> Incluye 100 impresiones</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> Acceso a un locker</p>
            <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i> Incluye café americano</p>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-purple rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className='bg-home-2 py-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
          <Col className='text-center text-light'>
            <h1 className='display-1 fw-bold theboldfont' data-aos-anchor-placement="top-bottom" data-aos="fade-up">NUESTROS PLANES</h1>
          </Col>
        </Row>
        <Row className='my-lg-5'>
          <Col md="6" lg="3" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center justify-content-between flex-column px-5 mt-5 mt-lg-0'>
            <div>
              <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start fs-3 my-3 fw-bold">Privado 1</h1>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Crea tu propio espacio en una oficina privada con todos los servicios.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>2 personas</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Horario de 9 am - 7 pm de lunes a viernes</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>40 horas (No acomulables)</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Café americanos incluido</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>No incluye impresiones ni acceso a lockers</p>
            </div>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-green rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
          <Col md="6" lg="3" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center justify-content-between flex-column px-5 mt-5 mt-lg-0'>
            <div>
              <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start fs-3 my-3 fw-bold">Privado 2</h1>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Amplia oficina privada con todos los servicios, diseñada especialmente para tus necesidades.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>1 personas</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Horario de 9 am - 7 pm de lunes a viernes</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>40 horas (No acomulables)</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Café americanos incluido</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>100 impresiones incluidas.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>No incluye acceso a lockers</p>
            </div>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-green rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
          <Col md="6" lg="3" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center justify-content-between flex-column px-5 mt-5 mt-lg-0'>
            <div>
              <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start fs-3 my-3 fw-bold">Privado 3</h1>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Oficinas diseñadas para generar un vinculo personal o laboral entre empresarios.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>2 personas</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Horario de 9 am - 7 pm de lunes a viernes</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>40 horas (No acomulables)</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Café americanos incluido</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>200 impresiones incluidas.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>No incluye acceso a lockers</p>
            </div>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-green rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
          <Col md="6" lg="3" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='d-flex align-items-center justify-content-between flex-column px-5 mt-5 mt-lg-0'>
            <div>
              <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start fs-3 my-3 fw-bold">Privado 4</h1>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary text-justify">Oficina privada con todos los servicios, diseñada especialmente para tus necesidades.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>3 personas</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Horario de 9 am - 7 pm de lunes a viernes</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>40 horas (No acomulables)</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>Café americanos incluido</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>200 impresiones incluidas.</p>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-secondary align-self-start d-flex mb-0"><i className='bx bx-check fw-bold fs-5 me-2'></i>No incluye acceso a lockers</p>
            </div>
            <a href="https://wa.me/3314358204" data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-green rounded text-white fw-bold d-inline-block py-1 px-2 fs-5 me-auto mt-4 text-decoration-none '>
              <p data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='mb-0 px-2'>Informes</p>
            </a>
          </Col>
        </Row>
      </Container>
      <Container fluid data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='bg-servicios-2 my-5'>
        <Container className='pt-5'>
          <Row className='pt-5'>
            <Col className='text-start text-light'>
              <h1 className='mt-5 display-5' data-aos-anchor-placement="top-bottom" data-aos="fade-up">QUE TU ESPACIO DE <br className='d-none d-lg-block' /> TRABAJO REFLEJE LA <br className='d-none d-lg-block' /><span className='fw-bold'>SOLIDEZ DE TU EMPRESA</span></h1>
              <Link to="/Contacto" className="btn btn-light py-1 mb-5 mt-3 fs-5" data-aos-anchor-placement="top-bottom" data-aos="fade-up">Contáctanos</Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className='mt-5'>
            <h1 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className="text-center text-secondary dsiplay-2 fw-bold">AMENIDADES</h1>
          <Col lg="4" className='text-center mt-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/recepcion.png" alt="recepcion" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>Área de recepción</h2>
          </Col>
          <Col lg="4" className='text-center mt-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/cafe.png" alt="cafe" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>Servicio de cafetería</h2>
          </Col>
          <Col lg="4" className='text-center mt-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/pantalla.png" alt="pantalla" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>Pantalla</h2>
          </Col>
          <Col lg="4" className='text-center mt-5 pt-lg-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/internet.png" alt="internet" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>IInternet de alta <br /> velocidad</h2>
          </Col>
          <Col lg="4" className='text-center mt-5 pt-lg-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/Mobiliario.png" alt="mobiliario" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>Mobiliario</h2>
          </Col>
          <Col lg="4" className='text-center mt-5 pt-lg-5'>
            <img data-aos-anchor-placement="top-bottom" data-aos="fade-up" height="60" src="assets/images/Servicios/terraza.png" alt="terraza" />
            <h2 data-aos-anchor-placement="top-bottom" data-aos="fade-up" className='text-secondary mt-3 fw-bold'>Terraza</h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className='mt-5 position-relative'>
        <img src="assets/images/Servicios/banner-2.jpg" data-aos-anchor-placement="top-bottom" data-aos="fade-up" alt="banner servicio" className='zIndex-1 position-absolute top-0 start-0 w-100 h-100 fit-image p-0' />
        <Container className='d-flex align-items-end position-relative zIndex-2 min-vh-100'>
          <Row className='mb-5'>
            <Col className='px-5 d-grid'>
              <div className='bg-white py-3 d-inline-block col-lg-10 mx-auto'>
                <h1 className='text-center display-6' data-aos-anchor-placement="top-bottom" data-aos="fade-up">SOMOS UNA <span className="fw-bold">RED DE NEGOCIOS</span> ENFOCADOS EN <span className="fw-bold">BRINDAR RESULTADOS INTEGRALES</span></h1>

              </div>
              <div data-aos-anchor-placement="top-bottom" data-aos="fade-up" style={{height:10}} className="bg-dark bg-opacity-50 rounded-pill mx-auto col-lg-1 mb-lg-5 mt-4"></div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className='mt-5 py-5 bg-home-2' data-aos-anchor-placement="top-bottom" data-aos="fade-up">
        <Container>
        <Row>
            <Col className='d-grid'>
              <h1 className='text-light text-center display-4 fw-bold py-5 my-2' data-aos-anchor-placement="top-bottom" data-aos="fade-up">Accede a beneficios exclusivos, enterate de noticias, eventos y ofertas.</h1>
              <Form data-aos-anchor-placement="top-bottom" data-aos="fade-up" onSubmit={handleSubmit} className='col-lg-5 d-flex rounded-pill bg-white bg-opacity-25 bd-highlight mx-auto'>
                <FloatingLabel label="Correo electrónico" className=' text-white fw-bold py-0 w-100'>
                  <Form.Control type="email" placeholder="Correo electrónico" className='bg-transparent border-0 rounded-pill text-white py-0' />
                </FloatingLabel> 
                <button type='submit' disabled={isDisabled} className='bg-white border-0 rounded-pill py-0 px-5'>{textForm}</button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
          {/* Same as */}
        <ToastContainer />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
        {/* Same as */}
        <ToastContainer />
      <Footer/>
    </>
  )
}

export default Servicios