import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Footer() {
  return (
    <div className='overflow-hidden'>
    <Container fluid className='bg-secondary bg-opacity-75 mt-5' data-aos="fade-up" data-aos-anchor-placement="top-bottom">
      <Container>
        <Row>
          <Col lg="4" className='d-grid border-end border-1 border-secondary '>
            <img src="assets/images/logo_enlace.png" alt="logo Enlace" className='img-fluid mx-auto px-3 mb-4 mt-5' data-aos="fade-up" data-aos-anchor-placement="top-bottom"  />
            <div className='mx-auto mb-5' data-aos="fade-up" data-aos-anchor-placement="top-bottom" >
              <a href="https://www.facebook.com/" target="_blank" className='bg-white text-secondary p-1 fs-3 rounded-3 d-inline-flex align-item-center text-decoration-none'><i className='bx bxl-facebook p-1' ></i></a>
              <a href="https://www.instagram.com/" target="_blank" className='bg-white text-secondary p-1 fs-3 rounded-3 mx-3 d-inline-flex align-item-center text-decoration-none'><i className='bx bxl-instagram p-1' ></i></a>
              <a href="https://www.linkedin.com/" target="_blank" className='bg-white text-secondary p-1 fs-3 rounded-3 d-inline-flex align-item-center text-decoration-none'><i className='bx bxl-linkedin p-1' ></i></a>
            </div>
          </Col>
          <Col lg="4" className='d-flex align-items-center justify-content-center flex-column px-5'>
            <div className='d-inline-flex align-items-center'>
              <i className='bx bx-map fs-2 text-white me-3' data-aos="fade-up" data-aos-anchor-placement="top-bottom" ></i>
              <a href="https://goo.gl/maps/zf435a9TMqPLJ7ePA" data-aos="fade-up" data-aos-anchor-placement="top-bottom" target="blank" className='text-decoration-none text-white fs-6 text-justify'>Herrera y Cairo No. 342, Colonia Centro, C.P. 45500, San Pedro Tlaquepaque</a>
            </div>
            <div className='d-inline-flex align-items-center mt-4'>
              <i class='bx bx-time-five fs-2 text-white me-3' data-aos="fade-up" data-aos-anchor-placement="top-bottom"></i>
              <p className='text-white fs-6 text-justify mb-0' data-aos="fade-up" data-aos-anchor-placement="top-bottom">Lunes a viernes de 9:00 am a 7:00 pm.</p>
            </div>
          </Col>
          <Col lg="4" className='d-grid mt-5 mt-lg-0 pb-5 pb-lg-0'>
            <div className='m-auto d-flex flex-column'>
              <div className='d-inline-flex align-items-center'>
                <i className='bx bx-phone fs-3 me-3 text-white' data-aos="fade-up" data-aos-anchor-placement="top-bottom" ></i>
                <a href="tel:3340089108" data-aos="fade-up" data-aos-anchor-placement="top-bottom" target="blank" className='text-decoration-none text-white fs-6'>(33) 4008 9108</a>
              </div>
              <div className='d-inline-flex align-items-center my-3'>
                <i class='bx bxl-whatsapp fs-3 me-3 text-white' data-aos="fade-up" data-aos-anchor-placement="top-bottom" ></i>
                <a href="https://wa.me/3314358204" data-aos="fade-up" data-aos-anchor-placement="top-bottom" target="blank" className='text-decoration-none text-white fs-6'>(33) 1435 8204</a>
              </div>
              <div className='d-inline-flex align-items-center'>
                <i className='bx bx-envelope fs-3 me-3 text-white' data-aos="fade-up" data-aos-anchor-placement="top-bottom" ></i>
                <a href="mailto:admin@enlace.mx" data-aos="fade-up" data-aos-anchor-placement="top-bottom" target="blank" className='text-decoration-none text-white fs-6'>admin@enlacecoworking.mx</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
    </div>
  )
}

export default Footer